import { useContext } from 'react'
import * as React from 'react';
import "../styles/Navbar.css";
import logo from "../assets/cara.png";
import { useState, useEffect } from 'react';
import BestSellers from './BestSellers';
import Body from './Body';
import { FaShoppingBag, FaChevronDown } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';
import { FaUser } from "react-icons/fa";
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { useSelector } from 'react-redux';

import { Button, Menu, MenuItem } from "@material-ui/core";
import {categories} from "../productsStore/Store";

const NavBar = () => {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      
    };
    const navigate = useNavigate();
    const handleClose = () => {
      setAnchorEl(null);
    //   navigate('/orders');
    };
    const [showDropdown, setShowDropdown] = useState(false);
    const handleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);

    const { logout } = useContext(AuthContext);
    const currentUser = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
    const {cartLen, populateCart} = useContext(AuthContext); 
    // const cartItems = useSelector((state) => state.user[currentUser.id].cartItems);
    useEffect(() => {
        populateCart(currentUser.id);
    }, []);

    // let totalQuantity = 0;
    // let totalQuantity = 0;
    // cartItems.forEach(item => {
    //     totalQuantity += item.quantity;
    // });

    const showHandler = () => {
        setShow(true)
        setShow2(false)
        setShow3(false)
        setShow4(false)

    }

    const showHandler2 = () => {
        setShow2(true)
        setShow(false)
        setShow3(false)
        setShow4(false)

    }

    const showHandler3 = () => {
        setShow3(true)
        setShow(false)
        setShow2(false)
        setShow4(false)
    }

    const showHandler4 = () => {
        setShow4(true)
        setShow(false)
        setShow2(false)
        setShow3(false)

    }


    const dontShowHandler = () => {
        setShow(false)
        setShow2(false)
        setShow3(false)
        setShow4(false)
        console.log('dont show');


    }

    const handleLogout = () => {
        console.log(currentUser);
        logout();
        navigate('/login');
    }
    

    return (
        <div>
            <header className="banner" role="banner">

                <nav className="navbar w-full justify-between" role="navigation" aria-label="menu">

                    <Link className="home-page" to="/">
                        <img src={logo} width="100" className=" ml-8" />
                    </Link>

                    <ul className="profile-cart">
                        <li className="dropdown nav-link nav-link-fade-up" onMouseOver={showHandler} >
                            <Link>
                            BEST SELLERS
                                {show && 
                                <div>
                                    <ul className="dropdown-nav" onMouseLeave={dontShowHandler} >
                                        <BestSellers onClick={dontShowHandler}></BestSellers>
                                    </ul>

                                </div>
                                }
                            </Link>
                        </li >
                        <li className="dropdown nav-link nav-link-fade-up" onMouseOver={showHandler3} >
                            <Link>
                                SHOP RANGE
                                {show3 && <ul className="dropdown-nav" onMouseLeave={dontShowHandler}>
                                    <Body onClick={dontShowHandler} />
                                </ul>}
                            </Link>
                        </li>
                        {Object.keys(categories).map(category => (
                            <li className="dropdown nav-link nav-link-fade-up" key={category}>
                                <Link to={"/category/" + category}>
                                    {categories[category]}
                                </Link>
                            </li>
                        ))}
                    </ul>

                    <ul className='profile-cart'>
                        <li>
                            <Link to="/cart">
                                {/* <FaShoppingBag />
                                {cartLen > 0 && <span className="cart-count">{cartLen}</span>} */}

                                <Badge badgeContent={cartLen} color="primary">
                                    <ShoppingCart color="action" />
                                </Badge>
                            </Link>
                        </li>
                        <li>
                        {currentUser ? (
                            <div className='user-profile'>
                                <div onClick={handleDropdown}>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        >
                                        <FaUser />
                                    </Button>
                                <Menu className='profile-btn'
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem className='cart-total-header'>
                                        <b>{currentUser.name}</b>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <Link to='/orders'>
                                            Orders
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        Logout
                                    </MenuItem>
                                </Menu>
                                </div>
                            </div>
                        ) : (
                            <Link to="/login">Login</Link>
                        )}
                            
                        </li>
                        

                    </ul>
                    

                    


                </nav >
            </header >



        </div >
    )
}

export default NavBar

