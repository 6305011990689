import React, {useMemo} from 'react'
import {store} from "../productsStore/Store";
import OurBestSellers from './OurBestSellers';

const Products = () => {
    const randomBestSellers = useMemo(() =>  store.filter(i => i.type === "ourBestSellers")
        .map(store => ({store, randomId: Math.random()}))
        .sort((a, b) => a.randomId - b.randomId)
        .map(v => ({...v.store})), []);
    return (<div className='ourBestSellersMainParent'>
            {randomBestSellers.map(((item) => {
                return (
                    <OurBestSellers
                        key={item.id}
                        id={item.id}
                        title={item.name}
                        price={item.price}
                        image={item.primaryImage}
                        product_category={item.product_category}
                    />
                )
            }))}
        </div>)
}

export default Products