import React from 'react'
import "../styles/HeroSection.css"
import one from "../assets/one.png"
import two from "../assets/two.png"
import three from "../assets/three.png"


const HeroSection = () => {
    return (
        <div className='heroSecMainParent'>

            <p className='text-4xl mt-20 text-center welcomStore'><b>WELCOME TO OUR STORE</b></p>
            <br />
            <br />
            <p className='heroDescription relative text-xl flex flex-wrap'> Bringing You the Elements of Style, Elevating Your Online Shopping Experience.</p>

            <div className='heroPicHold flex flex-row '>
                <div>
                    <img src={one} className=" w-20" />
                    <p> Nature Friendly </p>
                </div>
                <div>
                    <img src={two} className=" w-20" />
                    <p> Exceptional Quality </p>
                </div>
                
            </div>

            <div className=' text-xl font-medium flex flex-row heroHeadingHold'>
            </div>

        </div>
    )
}

export default HeroSection
