import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import "../styles/Navbar.css";
import logo from "../assets/cara.png";
import { Link } from 'react-router-dom';
import { users } from '../productsStore/UserStore';
import { AuthContext } from '../App';

import {
    Container,
    Typography,
    TextField,
    Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(8),
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(5),
    },
    textField: {
        marginBottom: theme.spacing(2),
        
    },
    submitButton: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoginPage = () => {
    const { login,setAddressCity, setAddressCountry, setAddressLine1, setAddressLine2, setAddressPostal, setAddressState} = useContext(AuthContext);
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(false);

    

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Email:', email);
        console.log('Password:', password);
        const loggedIn = await login(email, password);
        console.log(loggedIn);
        if (loggedIn) {
            navigate("/");
        } else {
            console.log("Login failed");
            setLoginError(true);
        }
            // users.find(user => {
            //     if (user.email === email && user.password === password) {
            //         login(user);
            //         setAddressCity(user.addressCity);
            //         setAddressCountry(user.addressCountry);
            //         setAddressLine1(user.addressLine1);
            //         setAddressLine2(user.addressLine2);
            //         setAddressState(user.addressState);
            //         setAddressPostal(user.addressPostal);
            //         navigate("/");
            //     }
            // });
    
    };

    // const isValidUser = (email, password) => {
    //     // Check if the email/password pair matches any user in the user store
    //     return users.some(user => user.email === email && user.password === password);
    // };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.container}>
                <Link to="/">
                    <img src={logo} width="40%"/>
                </Link>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="standard"
                        label="Email Address"
                        type="email"
                        fullWidth
                        className={classes.textField}
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <TextField
                        variant="standard"
                        label="Password"
                        type="password"
                        fullWidth
                        className={classes.textField}
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.submitButton}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
        </Container>
    );
};

export default LoginPage;
