import React, { useContext } from 'react'
import { store } from '../productsStore/Store'
import "../styles/OurBestSellers.css"
import { useDispatch } from 'react-redux'
import { cartActions } from '../redux-state/CartState'

import { Link } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { userActions } from '../redux-state/UserState'
import { AuthContext } from '../App'


const OurBestSellers = (props) => {

    const { title, price, id, image, product_category } = props;

    const currentUser  = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
    const {setCartLen, setCartItems} = useContext(AuthContext);
    const addItemToCartHandler = (e) => {
        console.log("adding item to cart");
        console.log("current user id is", currentUser.id);

        const addItem = async () => {
            var myHeaders = new Headers();
            myHeaders.append("userId", currentUser.id);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "userId": null,
                "itemId": id,
                "name": title,
                "description": "The number one facial cream out there.",
                "price": price,
                "quantity": 1,
                "category": product_category,
                "imageTag": image
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };
            

            const result = await fetch("http://104.196.11.202:8080/v1/item", requestOptions);
            const resultJson = await result.json();
            if (resultJson.length > 0) {
                setCartItems(resultJson);   
                var total = 0;
                resultJson.map((item) => {
                    total += item.quantity;
                });
                setCartLen(total);
                console.log("Item added to cart");
            }
        };
        addItem();
    }

    const toast = useToast();




    return (
        <div>
            <div key={id}>

                <div className="card w-96 bg-base-100 box-shadow zoom-item">

                    <Link to={`/${id}`}>
                        <figure className="px-10 pt-10">
                            <img src={image} alt="Shoes" className="rounded-xl best-seller-image" />
                        </figure>

                    </Link>
                    <div className="card-body items-center text-center">
                        <h2 className="card-title mb-1 font-bold text-xl">{title}</h2>
                        <h2 className=""><b>${price}</b></h2>


                        <div className="card-actions px-10 w-full" onClick={() =>
                            toast({
                                title: '',
                                description: "Successfully Added",
                                status: 'success',
                                duration: 1500,
                                isClosable: true,
                            })
                        }>
                            <button className="btn btn-primary w-full" onClick={addItemToCartHandler}>Buy Now</button>
                        </div>

                    </div>

                </div>

            </div>



        </div>
    )
}

export default OurBestSellers
