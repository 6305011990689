// Import necessary Redux dependencies
import { configureStore } from "@reduxjs/toolkit";

// Import your slices
import userReducer from './redux-state/UserState';

// Create a single store with your slices
const store = configureStore({
    reducer: {
        // cart: cartReducer,
        // orders: ordersReducer
        user: userReducer,
    }, 
    preloadedState: localStorage.getItem("cartOrderState") != null
        ? JSON.parse(localStorage.getItem("cartOrderState"))
        : {}
});


// Save state to local storage whenever the state changes
store.subscribe(() => {
    localStorage.setItem("cartOrderState", JSON.stringify(store.getState()));
});

export default store;