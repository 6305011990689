import React from 'react'
import cartgif from "../assets/cartGif.gif";
import "../styles/BestSellers.css";

const EmptyOrder = () => {
    return (
        <div className=' emptyCartMainParent text-center relative top-20 h-full'>

            <div id='fs'>
                <p className=' text-3xl fof ' id='fs'>No orders placed! </p>
                <p>You may have products in your cart</p>
            </div>
        </div>
    )
}

export default EmptyOrder