import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import car1 from "../assets/car1.png"
import car2 from "../assets/car2.png"
import car3 from "../assets/car3.png"
import car4 from "../assets/car4.png"
import car6 from "../assets/car6.png"
;


export default class SimpleSlider extends Component {

  render() {
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: false
    };
    return (
      <div className="carousel-contain mt-6">
        <Slider {...settings}>

        <div>
            <img src={car6} className="car-pic" />
          </div>
          <div>
            <img src={car4} className="car-pic" />
          </div>

          <div>
            <img src={car2} className="car-pic" />
          </div>

          <div>
            <img src={car3} className="car-pic" />
          </div>



        </Slider>
      </div>
    );
  }
}