export const categories = {
    bodyCare: "Body Care",
    shoes: "Shoes", clothes: "Clothes",
    bags: "Bags",
    accessories: "Accessories"
}
export const store =


    [
        {
            id: 'SKU4728',
            name: 'Facial Cleanser',
            price: 9,
            type: 'bestSeller',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Foaming_Facial_Cleanser_125ml_01_Product_540x.jpg?v=1599504600',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Foaming_Facial_Cleanser_125ml_OH_01_1_540x.jpg?v=1599504601',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU4187',
            name: 'Mist Toner',
            price: 9,
            type: 'bestSeller',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Mist_Toner_125ml_01_Product_540x.jpg?v=1599504641',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Mist_Toner_125ml_OH_01_540x.jpg?v=1599504641',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU1496',
            name: 'Rejuvenating Face Mask',
            price: 9,
            type: 'bestSeller',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Super_Greens_Detoxifying_Facial_Masque_540x.jpg?v=1599504639',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Detoxifying-Clay-Masque_01_540x.jpg?v=1599504639',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU6540',
            name: 'ROSE GOLD PACK',
            price: 12,
            type: 'gift',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Rose_Gift_Pack_Front_360x.jpg?v=1602265134',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Rose_Gift_Pack_Angle_360x.jpg?v=1602265134',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU7070',
            name: 'HAND CREAM TRIO PACK',
            price: 10,
            type: 'gift',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hand_Cream_Trio_Gift_Pack_Front_360x.jpg?v=1602265134',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hand_Cream_Trio_Gift_Pack_Angle_360x.jpg?v=1602265134',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU4080',
            name: 'THE LAVENDER PACK',
            price: 15,
            type: 'gift',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/TheLavenderPack1000x1000_360x.jpg?v=1604413498',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/9327693009396_-TheLavenderPack_360x.jpg?v=1602265136',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU2099',
            name: 'COFFEE BODY SCRUB',
            price: 11,
            type: 'navbar-BodyType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Coffee___Coconut_Energising_Body_Scrub_200ml_01_Product_540x.jpg?v=1599504583',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Coffee___Coconut_Energising_Body_Scrub_200ml_OH_01_540x.jpg?v=1599504583',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU3349',
            name: 'COCONUT BODY WASH',
            price: 18,
            type: 'navbar-BodyType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Botanical_Body_Wash_1L_01_Product_540x.jpg?v=1599504644',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/AloeVera-styled-shot_c2cadf15-cec0-4788-8cb5-10c22e5ccfd7_540x.jpg?v=1599504644',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU2247',
            name: 'COCONUT BODY WASH',
            price: 21,
            type: 'navbar-BodyType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Body_Lotion_500mL_01_Product_540x.jpg?v=1599504585',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site-SIG_fd44fcf9-5f2e-4a03-9deb-06e175447fd7_540x.png?v=1599504585',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU3422',
            name: 'FOAMING FACE WASH',
            price: 56,
            type: 'navbar-TravelType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Foaming_Facial_Cleanser_50mL_Tube_WEB.jpg?v=1599504567',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/IMG_8764_360x.jpg?v=1602265059',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU5296',
            name: 'HYDRATING MIST TONER',
            price: 28,
            type: 'navbar-TravelType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating_Mist_Toner_50mL_WEB_540x.jpg?v=1599504599',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/239024Sukin-SocialContentShoot_321_360x.jpg?v=1599504599',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU339',
            name: 'REVITAL FACE WASH',
            price: 22,
            type: 'navbar-TravelType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Revitalising_Facial_Scrub_50mL_Tube_WEB_540x.jpg?v=1599504603',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/239024Sukin-SocialContentShoot_341_1_360x.jpg?v=1602265081',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU3482',
            name: 'UBTABN FACEWASH',
            price: 44,
            type: 'navbar-TravelType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Natural_Balance_Shampoo_50mL_Tube_Grey_BG_FOR_WEB_540x.jpg?v=1599504616',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/239024Sukin-SocialContentShoot_341_360x.jpg?v=1599504616',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU336',
            name: 'ROSE WATER',
            price: 52,
            type: 'navbar-NewType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Rose_Mist_Toner_125ml_01_Product_540x.jpg?v=1599504649',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Rose_Mist_Toner_125ml_Green_Tea_Mist_Toner_125ml_01_540x.jpg?v=1599504649',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU8836',
            name: ' COFFEE FACE MASK',
            price: 47,
            type: 'navbar-NewType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Coffee_Coconut_Exfoliating_Masque_540x.jpg?v=1599504663',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Coffee___Coconut_Exfoliating_Facial_Masque_100ml_OH_01_540x.jpg?v=1599504663',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU3751',
            name: 'MIST TONER',
            price: 21,
            type: 'navbar-NewType',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Rosehip_Oil-25ml-Product_540x.jpg?v=1599504623',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site-RH_62038fc0-558f-45e6-b0cd-061fba08bfd8_540x.png?v=1599504623',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU7475',
            name: 'MIST TONER',
            price: 21,
            type: 'ourBestSellers',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Mist_Toner_125ml_01_Product_540x.jpg?v=1599504641',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Mist_Toner_125ml_OH_01_360x.jpg?v=1599504641',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU5605',
            name: 'ROSE GOLD FACIAL',
            price: 61,
            type: 'ourBestSellers',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Rose_Mist_Toner_125ml_01_Product_540x.jpg?v=1599504649',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Rose_Mist_Toner_125ml_Green_Tea_Mist_Toner_125ml_01_360x.jpg?v=1599504649',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU2333',
            name: 'FOAMING ROSE FACIAL',
            price: 45,
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Facial_Moisturiser_125ml_01_Product_540x.jpg?v=1599504612',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Facial-Moisturiser_01_540x.jpg?v=1599504612',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU1582',
            name: 'REVITAL FACE WASH',
            price: 84,
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Micellar_Cleansing_Water_250ml_01_Product_540x.jpg?v=1599504588',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Micellar_Cleansing_Water_250ml_OH_01_540x.jpg?v=1599504588',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU3917',
            name: 'EXFOLIATING MASK',
            price: 25,
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Cream_Cleanser_125ml_01_Product_540x.jpg?v=1599504664',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Cream_Cleanser_125ml_OH_01_540x.jpg?v=1599504664',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU3711',
            name: 'APPLE CIDER CREAM',
            price: 45,
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Revitalising_Facial_Scrub_125ml_01_Product_540x.jpg?v=1599504656',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Facial_Masque_Revitalising_Facial_Scrub_125ml_OH_01_540x.jpg?v=1599504656',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU1562',
            name: 'AVOCADO CREAM',
            type: 'ourBestSellers',
            price: 157,
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Facial_Masque_01_Product_2_540x.jpg?v=1599504579',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Facial_Masque_OH_01_540x.jpg?v=1599504579',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU2807',
            name: 'UBTAN FACEWASH',
            price: 24,
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Antioxident_Eye_Serum_01_Product_540x.jpg?v=1599504580',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Antioxidant_Eye_Cream_30ml_01_540x.jpg?v=1599504580',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU4934',
            name: 'ANTIOXIDANT FACEMASK',
            price: 75,
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin-Paw-Paw_540x.jpg?v=1599504587',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/sukin_pawpaw_540x.jpg?v=1599504587',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU401',
            name: 'COFFEE BODY WASH',
            price: 78,
            category: 'Body Care',
            type: 'ourBestSellers',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Moisture_Restoring_Night_Cream_120ml_01_Product_2_540x.jpg?v=1599504662',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Moisture_Restoring_Night_Cream_120ml_01_540x.jpg?v=1599504662',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU9450',
            name: 'STRAWBERRY MOISTURISER',
            price: 57,
            category: 'Body Care',
            type: 'ourBestSellers',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Purifying_Facial_Masque_01_Product_2_540x.jpg?v=1599504582',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Facial_Masque_OH_01_540x.jpg?v=1599504579',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU4925',
            name: 'BLUEBERRY MOISTURISER',
            price: 88,
            category: 'Body Care',
            type: 'ourBestSellers',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Signature_Hydrating_Body_Lotion_500ml_02_540x.jpg?v=1599504645',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating-Body-Lotion_02_540x.jpg?v=1599504645',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU7646',
            name: 'NATURAL LEMON BODY WASH',
            price: 18,
            type: 'bodyCare all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NB_ScalpScrub200mlTube_200mL_02_large.jpg?v=1654006879',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site-SIG_3ec6e6c0-aee5-483d-a6c9-e4abab736fd7_large.png?v=1654006879',
            stars: 4,
            descr: 'Our lightweight Leave-In Conditioner hydrates, gently detangles and tames frizz leaving hair smooth and manageable.            ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU9397',
            name: 'NATURAL STRAWBERRY BODY WASH',
            price: 15,
            type: 'bodywash all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NB_Leave-InConditioner_250ml_02_large.jpg?v=1654006878',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NaturalBalance_7be6d9d2-0387-44f2-9949-b03a344fc044_large.jpg?v=1654006878',
            stars: 5,
            descr: 'Our Scalp Scrub removes product build up and impurities with a refreshing blend of Peppermint & Ginger Extract.        ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU7231',
            name: 'NATURAL UBTAN BODY WASH',
            price: 15,
            type: 'bodywash all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ROW_PAMicroExfoliatingCleanser125mL_1500px_large.jpg?v=1618352423',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ROW_PAMicroExfoliatingCleanser125mL_1500px_large.jpg?v=1618352423',
            stars: 5,
            descr: 'Hydrate and protect dry hands while caring for nails and cuticles through Soy Protein, Aloe Vera & Vitamin E',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU6252',
            name: 'NATURAL NEEM BODY WASH',
            price: 11,
            type: 'bodywash all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Colour_Care_Lustre_Masque_200mL_02_For_Web_large.jpg?v=1616509409',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ColourCare2_large.jpg?v=1616509409',
            stars: 5,
            descr: 'Seriously hydrate the delicate under eye area with our fragrance-free water gel.            ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU1923',
            name: 'NATURAL BLUEBERRY BODY LOTION',
            price: 15,
            type: 'bodyLotion all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/CreamyBodyWash500ml_large.jpg?v=1649168513',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/CreamyBodyFinal_large.jpg?v=1649168513',
            stars: 5,
            descr: 'Deliver an instant boost of moisture to skin with our ultra-hydrating gel cream.',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU5135',
            name: 'NATURAL KIWI BODY LOTION',
            price: 19,
            type: 'bodyLotion all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Biomarine_Facial_Serum_02_FOR_WEB_large.jpg?v=1646144556',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/MicrosoftTeams-image_5_-SMALL_large.jpg?v=1646144556',
            stars: 5,
            descr: 'Our glow-promoting cleanser gently exfoliates to remove dead skin cells, excess sebum & make-up.',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU5379',
            name: 'NATURAL AVOCADO BODY LOTION',
            price: 14,
            type: 'bodyLotion all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydra_Eye_Gel_15ml_Tube_02_FOR_WEB_large.jpg?v=1646144555',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/MicrosoftTeams-image_5_-SMALL_533a775e-6e81-48de-bafa-6250300a0219_large.jpg?v=1646144555',
            stars: 5,
            descr: 'Our glow-promoting exfoliator is formulated with Kakadu plum, a natural source of Vitamin C, and Bromelain, a natural fruit...            ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU1815',
            name: 'NATURAL LITCHI BODY LOTION',
            price: 16,
            type: 'bodyLotion all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ROW_SPFSheerTouchFacialSunscreen-Light-MediumTubeFRONT02_1000_large.jpg?v=1614090206',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/SukinSPF30UCG_d249fc5d-2cd5-4be7-afbd-8f11d545261f_large.jpg?v=1614090207',
            stars: 5,
            descr: 'Kakadu Plum, a natural source of Vitamin C, Vitamin E and Australian bioactive complex rich in antioxidants brighten and...            ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU2527',
            name: 'NATURAL TEATREE BODY LOTION',
            price: 18,
            type: 'bodyLotion all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/ROW_SPFSheerTouchFacialSunscreen-UntintedTubeFRONT02_1000px_large.jpg?v=1614090205',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/SukinSPF30UCG_large.jpg?v=1614090205',
            stars: 5,
            descr: 'Our Scalp Scrub removes product build up and impurities with a refreshing blend of Peppermint & Ginger Extract.        ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU3339',
            name: 'NATURAL TEATREE BODY LOTION',
            price: 18,
            type: 'bodyLotion all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Rosehip_Facial_Masque_large.jpg?v=1599504675',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/STYLED_ROSE_FACIAL_MASQUE_large.jpg?v=1599504675',
            stars: 5,
            descr: 'Our soap-free Bergamot & Patchouli Body Wash delights and stimulates the senses while leaving skin clean and fresh.            ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU8932',
            name: 'EXFOLIATING JELLY SCRUB',
            price: 19,
            type: 'bodyScrub all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Brightening_JellyExfoliator125ml_02_large.jpg?v=1629210220',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site_BRIGHTENING_451cbd3f-1e8b-446a-a45e-61cc31826f69_large.jpg?v=1629210220',
            stars: 5,
            descr: 'Our cleanser helps exfoliate dead skin cells & cleanses away impurities to promote a more even complexion.            ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU2889',
            name: 'EXFOLIATING APPPLE SCRUB',
            price: 9,
            type: 'bodyScrub all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Brightening_IlluminatingMoisturiser60ml_02_large.jpg?v=1629210218',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/NO_Lists_for_Site_BRIGHTENING_cf1ff5a7-5a32-4bb0-a7ce-be69ccc9fcf0_large.jpg?v=1629210219',
            stars: 5,
            descr: ' Our Color Luster Mask restores natural shine and radiance to color treated hair with Kakadu Plum & Birch Extracts.            ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU9584',
            name: 'EXFOLIATING KIWI SCRUB',
            price: 16,
            type: 'bodyScrub all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Bergamot_PachouliBodyWash500mL1000x1000x144_large.jpg?v=1637072534',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Bergamot_PachouliBodyWashpumpLifestyle1000x1000x144_large.jpg?v=1637072534',
            stars: 5,
            descr: 'Our Scalp Scrub removes product build up and impurities with a refreshing blend of Peppermint & Ginger Extract.        ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU5830',
            name: 'EXFOLIATING NEEM SCRUB',
            price: 16,
            type: 'bodyScrub all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating_Treatment_Oil_50mL_Bottle_01_2_large.jpg?v=1599504672',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Hydrating_Hair_Oil_large.jpg?v=1599504672',
            stars: 5,
            descr: 'Our natural, sheer touch tinted sunscreen for light to medium skin tones has been designed with daily use in...            ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU3967',
            name: 'EXFOLIATING COCOA SCRUB',
            price: 16,
            type: 'bodyScrub all',
            category: 'Body Care',
            primaryImage: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Sukin_Coffee_Coconut_Exfoliating_Masque_large.jpg?v=1599504663',
            hoverImg: 'https://cdn.shopify.com/s/files/1/0081/7374/8305/products/Masterbrand_Coffee___Coconut_Exfoliating_Facial_Masque_100ml_OH_01_large.jpg?v=1599504663',
            stars: 5,
            descr: 'Our 100% naturally derived, sheer touch facial sunscreen has been designed with daily use in mind, and heroes natural...            ',
            product_category: 'Cosmetics_body_care'
        },
        {
            id: 'SKU700',
            type: 'iGPosts',
            primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313962865_130486163124825_3872437440492064747_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=-lmG2O4dOucAX8QcJ5M&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAHT7pOcFYxRAnf56OnXFtLSyR7WayrzECFZkdsW9r_Rw&oe=63731B07'
        },
        {
            id: 'SKU1808',
            type: 'iGPosts',
            primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314474189_3459125960988849_5301653039305226582_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Py6h3bGoC_MAX-Iews1&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCRfHYUT_SCZWQYEKljTugVmBowTNDFYavEXU44ET63yg&oe=6372D043'
        },
        {
            id: 'SKU109',
            type: 'iGPosts',
            primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314478179_427847786212552_2166145489506263286_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=2EMtwONXdlgAX-YTXAC&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCNZD2XX5ypy58-6eUsbaHb2adjwe2LT6_UhoyCDB-JEQ&oe=637318F2'
        },
        {
            id: 'SKU383',
            type: 'iGPosts',
            primaryImage: '[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3)'
        },
        {
            id: 'SKU9667',
            type: 'iGPosts',
            primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313904838_644828754033309_8190401506066273847_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=5vGwQp3agyYAX9GzdN9&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCpWTRt3wjB65-i_cMABs2uurxkJxlaDO-j0ZAPoeR3lw&oe=63723C04'
        },
        {
            id: 'SKU1471',
            type: 'iGPosts',
            primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313842695_842398890281843_8923876477054992822_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=FKdEGPiWctQAX9PD1Tt&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD5A7KTnVB45p6M9Xl9zQSm15B1vnoU6JASPBgW62G_JA&oe=6373D0C0'
        },
        {
            id: 'SKU8139',
            type: 'iGPosts',
            primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313360482_421805233489433_2180398075982936387_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=TC_YP3KsBKgAX_UWuiL&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDL0rgDBFQc5kpLJiasFCbpam_yHNOF1Eqr6tkjJyjW0w&oe=6372D9EE'
        },
        {
            id: 'SKU9153',
            type: 'iGPosts',
            primaryImage: '[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/312923519_415357727475132_3000659168966820929_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=MSPAwUxDLT0AX_q9fA5&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDkzxVRC1ejkowXInjU7aaYjuS6iDBJV372Q0p9R1Amkg&oe=63738D15](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/312923519_415357727475132_3000659168966820929_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=MSPAwUxDLT0AX_q9fA5&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDkzxVRC1ejkowXInjU7aaYjuS6iDBJV372Q0p9R1Amkg&oe=63738D15)'
        },
        {
            id: 'SKU7465',
            type: 'iGPosts',
            primaryImage: '[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314538850_842391733849053_5837409325218948914_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=yl2x9QL1CI0AX_3K42M&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDtUpG3NMqPdhdjhPSsqtXIWhp63oIPCOXxHQUmp1Ro8A&oe=6373247D](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314538850_842391733849053_5837409325218948914_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=yl2x9QL1CI0AX_3K42M&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDtUpG3NMqPdhdjhPSsqtXIWhp63oIPCOXxHQUmp1Ro8A&oe=6373247D)'
        },
        {
            id: 'SKU3033',
            type: 'iGPosts',
            primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313918031_164815222899324_5118105442635469994_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=75C5M_xl7W4AX9aXcJZ&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBPLXtxavxi0qLvoBLwTHOcTh_WqHuC3dhATNZ7FhFVyw&oe=6372D076'
        },
        {
            id: 'SKU8487',
            type: 'iGPosts',
            primaryImage: '[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3)'
        },
        {
            id: 'SKU3137',
            type: 'iGPosts',
            primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313859159_1178762139722510_2208267463138928303_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=fDD-DllQ_fgAX_gepIB&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfA22pcsJqnauAqOBE2egFF1VhPe-wEPMDIQMOxhw2zCMw&oe=63741165'
        },
        {
            id: 'SKU898',
            name: 'The Ravello Loafer',
            price: 180,
            type: 'loafer',
            category: 'Shoes',
            stars: 5,
            descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
            primaryImage: 'https://rothys.com/cdn/shop/files/123_RavelloLoafer_StoneBlack_OnFigure_0730_650x.jpg?v=1699033803',
            hoverImg: 'https://rothys.com/cdn/shop/files/123_RavelloLoafer_StoneBlack_OnFigure_0730_650x.jpg?v=1699033803',
            product_category: 'Shoes'
        },
        {
            id: 'SKU8353',
            name: 'The Sand Loafer',
            price: 200,
            type: 'ourBestSellers',
            category: 'Shoes',
            stars: 5,
            descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
            primaryImage: 'https://images.unsplash.com/photo-1615979474401-8a6a344de5bd?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bG9hZmVyfGVufDB8fDB8fHwy',
            hoverImg: 'https://images.unsplash.com/photo-1615979474401-8a6a344de5bd?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bG9hZmVyfGVufDB8fDB8fHwy',
            product_category: 'Shoes'
        },
        {
            id: 'SKU5574',
            name: 'The Navy Loafer',
            price: 180,
            type: 'loafer',
            category: 'Shoes',
            stars: 5,
            descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
            primaryImage: 'https://images.unsplash.com/photo-1616406432452-07bc5938759d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bG9hZmVyfGVufDB8fDB8fHwy',
            hoverImg: 'https://images.unsplash.com/photo-1616406432452-07bc5938759d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bG9hZmVyfGVufDB8fDB8fHwy',
            product_category: 'Shoes'
        },
        {
            id: 'SKU8971',
            name: 'The Boot Sneakers',
            price: 201,
            type: 'loafer',
            category: 'Shoes',
            stars: 5,
            descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
            primaryImage: 'https://rothys.com/cdn/shop/products/056_Hudson_pdp_B_650x.jpg?v=1628807453',
            hoverImg: 'https://rothys.com/cdn/shop/products/056_Hudson_pdp_B_650x.jpg?v=1628807453',
            product_category: 'Shoes'
        },
        {
            id: 'SKU6832',
            name: 'The Arctic Sneakers',
            price: 201,
            type: 'shoe',
            category: 'Shoes',
            stars: 5,
            descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
            primaryImage: 'https://rothys.com/cdn/shop/files/056_RS01_ArcticStone_Side_650x.jpg?v=1706919590',
            hoverImg: 'https://rothys.com/cdn/shop/files/056_RS01_ArcticStone_Side_650x.jpg?v=1706919590',
            product_category: 'Shoes'
        },
        {
            id: 'SKU8863',
            name: 'Full zip jacket',
            price: 30,
            type: 'ourBestSellers',
            category: 'Clothes',
            stars: 5,
            descr: 'The next generation of our lightweight, four-way stretch soft shell jacket features a brushed interior for even more comfort.',
            primaryImage: 'https://images.unsplash.com/photo-1551028719-00167b16eac5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8amFja2V0fGVufDB8fDB8fHwy',
            hoverImg: 'https://images.unsplash.com/photo-1551028719-00167b16eac5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8amFja2V0fGVufDB8fDB8fHwy',
            product_category: 'Fashion'
        },
        {
            id: 'SKU7480',
            name: 'Long puffer jacket',
            price: 40,
            type: 'jacket',
            category: 'Clothes',
            stars: 5,
            descr: 'The next generation of our lightweight, four-way stretch soft shell jacket features a brushed interior for even more comfort.',
            primaryImage: 'https://www.proozy.com/cdn/shop/products/196601110672-jpg-6.jpg?v=1708947832&width=900',
            hoverImg: 'https://www.proozy.com/cdn/shop/products/196601110672-jpg-6.jpg?v=1708947832&width=900',
            product_category: 'Fashion'
        },
        {
            id: 'SKU7836',
            name: 'Nexus puffer jacket',
            price: 30,
            type: 'jacket',
            category: 'Clothes',
            stars: 5,
            descr: 'The next generation of our lightweight, four-way stretch soft shell jacket features a brushed interior for even more comfort.',
            primaryImage: 'https://images.unsplash.com/photo-1611312449408-fcece27cdbb7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8amFja2V0fGVufDB8fDB8fHwy',
            hoverImg: 'https://images.unsplash.com/photo-1611312449408-fcece27cdbb7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8amFja2V0fGVufDB8fDB8fHwy',
            product_category: 'Fashion'
        },
        {
            id: 'SKU5230',
            name: 'Sherpa zip jacket',
            price: 40,
            type: 'ourBestSellers',
            category: 'Clothes',
            stars: 5,
            descr: 'The next generation of our lightweight, four-way stretch soft shell jacket features a brushed interior for even more comfort.',
            primaryImage: 'https://www.proozy.com/cdn/shop/files/196601906442-1.jpg?v=1705074721&width=900',
            hoverImg: 'https://www.proozy.com/cdn/shop/files/196601906442-1.jpg?v=1705074721&width=900',
            product_category: 'Fashion'
        },
        {
            id: 'SKU2664',
            name: 'scarf',
            price: 30,
            type: 'accessories',
            category: 'Accessories',
            stars: 5,
            descr: 'The no-sacrifices carry-on that’s rugged enough to handle whatever travel throws your way.',
            primaryImage: 'https://images.unsplash.com/photo-1457545195570-67f207084966?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NhcmZ8ZW58MHx8MHx8fDA%3D',
            hoverImg: 'https://images.unsplash.com/photo-1457545195570-67f207084966?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NhcmZ8ZW58MHx8MHx8fDA%3D',
            product_category: 'Fashion'
        },
        {
            id: 'SKU6719',
            name: 'Check scarf',
            price: 30,
            type: 'ourBestSellers',
            category: 'Accessories',
            stars: 5,
            descr: "What's winter without a check scarf?",
            primaryImage: 'https://images.unsplash.com/photo-1609803384069-19f3e5a70e75?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c2NhcmZ8ZW58MHx8MHx8fDI%3D',
            hoverImg: 'https://images.unsplash.com/photo-1609803384069-19f3e5a70e75?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c2NhcmZ8ZW58MHx8MHx8fDI%3D',
            product_category: 'Fashion'
        },
        {
            id: 'SKU5058',
            name: 'Glasses',
            price: 30,
            type: 'accessories',
            category: 'Accessories',
            stars: 5,
            descr: 'Yellow glasses',
            primaryImage: 'https://images.unsplash.com/photo-1565624473621-4368231d26c5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Z2xhc3NlfGVufDB8fDB8fHwy',
            hoverImg: 'https://images.unsplash.com/photo-1565624473621-4368231d26c5?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Z2xhc3NlfGVufDB8fDB8fHwy',
            product_category: 'Fashion'
        },
        {
            id: 'SKU4881',
            name: 'Glasses 2',
            price: 30,
            type: 'accessories',
            category: 'Accessories',
            stars: 5,
            descr: 'Reading glasses',
            primaryImage: 'https://images.unsplash.com/photo-1602703522866-fb486308da5d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Z2xhc3NlfGVufDB8fDB8fHwy',
            hoverImg: 'https://images.unsplash.com/photo-1602703522866-fb486308da5d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Z2xhc3NlfGVufDB8fDB8fHwy',
            product_category: 'Fashion'
        },
        {
            id: 'SKU702',
            name: 'Phone cover',
            price: 30,
            type: 'ourBestSellers',
            category: 'Accessories',
            stars: 5,
            descr: 'Phone cover',
            primaryImage: 'https://images.unsplash.com/photo-1581003989510-ba20094d343c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cGhvbmUlMjBjb3ZlcnxlbnwwfHwwfHx8Mg%3D%3D',
            hoverImg: 'https://images.unsplash.com/photo-1581003989510-ba20094d343c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cGhvbmUlMjBjb3ZlcnxlbnwwfHwwfHx8Mg%3D%3D',
            product_category: 'Electronics'
        },
        {
            id: 'SKU9406',
            name: '3L BAG',
            price: 40,
            type: 'bag',
            category: 'Bags',
            stars: 5,
            descr: 'Keep everything you need for a day on the water — out of the water..',
            primaryImage: 'https://yeti-web.imgix.net/56b3cdd3970cc189/W-2301…w=500&q=68&h=500&fit=fill&hattr=500px&wattr=500px',
            hoverImg: 'https://yeti-web.imgix.net/56b3cdd3970cc189/W-2301…w=500&q=68&h=500&fit=fill&hattr=500px&wattr=500px',
            product_category: 'Fashion'
        },
        {
            id: 'SKU4046',
            name: '35 CARRYALL TOTE BAG',
            price: 140,
            type: 'bag',
            category: 'Bags',
            stars: 5,
            descr: 'Not too small, not too big, perfectly tough.',
            primaryImage: 'https://yeti-web.imgix.net/58e9190b07ab82db/W-2300…w=500&q=68&h=500&fit=fill&hattr=500px&wattr=500px',
            hoverImg: 'https://yeti-web.imgix.net/56b3cdd3970cc189/W-2301…w=500&q=68&h=500&fit=fill&hattr=500px&wattr=500px',
            product_category: 'Fashion'
        },
        {
            id: 'SKU398',
            name: '28L WATERPROOF BAG',
            price: 140,
            type: 'bag',
            category: 'Bags',
            stars: 5,
            descr: 'Not too small, not too big, perfectly tough.',
            primaryImage: 'https://yeti-web.imgix.net/68938bb60b4815f0/W-site…w=500&q=68&h=500&fit=fill&hattr=500px&wattr=500px',
            hoverImg: 'https://yeti-web.imgix.net/68938bb60b4815f0/W-site…w=500&q=68&h=500&fit=fill&hattr=500px&wattr=500px',
            product_category: 'Fashion'
        },
        {
            id: 'SKU246',
            name: '35L WHEELED LUGGAGE',
            price: 350,
            type: 'bag',
            category: 'Bags',
            stars: 5,
            descr: 'The no-sacrifices carry-on that’s rugged enough to handle whatever travel throws your way.',
            primaryImage: 'https://yeti-web.imgix.net/339fa08e1dbd2fbf/W-Bags…w=500&q=68&h=500&fit=fill&hattr=500px&wattr=500px',
            hoverImg: 'https://yeti-web.imgix.net/339fa08e1dbd2fbf/W-Bags…w=500&q=68&h=500&fit=fill&hattr=500px&wattr=500px',
            product_category: 'Fashion'
        }
    ]