import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { orderActions} from '../redux-state/OrdersState';
import { useDispatch } from 'react-redux';
import { cartActions } from '../redux-state/CartState';
import { userActions } from '../redux-state/UserState';
import { AuthContext } from '../App';
import { Modal } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    dialogActions: {
        justifyContent: 'center',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const Policy = (props) => {
    const useDummyData = false;
    const [checkoutLoader, setCheckoutLoader] = React.useState(false);
    const [cartId, setCartId] = React.useState("");
    const [policies, setPolicies] = React.useState([]);
    const dispatch = useDispatch();
    const currentUser = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;

    const { cartLen, cartItems, setCartLen, setCartItems } = useContext(AuthContext);
    let hostUrl = 'https://api.pinch.ai';

    const totalPrice = cartItems.map(item => {
        return item.quantity * item.price;
    }).reduce((totalPrice, singleItemPrice) => totalPrice + singleItemPrice, 0);

    const [policyData, setPolicyData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setCheckoutLoader(true);
        handleCheckout(cartId, policies);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const useCurrentItems = () => {
        const currentItems = useSelector((state) => state.user[currentUser.id].cartItems);
        return currentItems;
    };

    const handleCheckout = () => {
        let orderId = "";
        let tempCartId = Date.now().toString();
        let tempTotalPr = 0;
        for (let i = 0; i < cartItems.length; i++) {
            tempTotalPr += cartItems[i].price * cartItems[i].quantity;
        }
        const sendPurchase = async () => {
            try {

                var myHeaders = new Headers();
                myHeaders.append("Accept", "*/*");
                myHeaders.append("Authorization", "13001zpuu-ea29f7bbf8f642d6a72c0a3149eed053");
                myHeaders.append("x-merchant-id", "13001");
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Access-Control-Allow-Origin","*");
                if (cartId) {
                    tempCartId = cartId;
                }
                var raw = JSON.stringify({
                "cartID": tempCartId,
                "merchantCartID": "ABB10903-1234",
                "orderStatus": "Pending",
                "purchasedPolicies": policies,
                "paymentInfo": [
                    {
                    "amount": {
                        "amount": tempTotalPr
                    },
                    "instrument": "CC-M",
                    "CVV": "234",
                    "postalCode": currentUser.postalCode,
                    "AID": "A000000003101001"
                    }
                ]
                });

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
                };

                // Purchase call
                const response = await fetch(hostUrl + "/cart/purchase", requestOptions)
                console.log("response: " + response.status + " " + response.statusText);
                if (!response.ok) {
                    throw new Error('Error completing purchase');
                }

                const result = await response.json();
                console.log("Result for purchase:" + result.orderID);
                orderId = result.orderID;
                console.log("orderId: " + orderId);
                console.log("Order Id before Order's section: " + orderId);
                sendDataToOrderList(orderId, tempTotalPr);
            } catch (error) {
                console.log("error: " + error);
                setError(error);
            }
        };

        sendPurchase();
    };

    const sendDataToOrderList = (responseOrderId, tempTotalPr) => {
        console.log("response orderId: " + responseOrderId);
        console.log(new Date().getTime());
        console.log("Order added at CartTotal.js");

        // // Delay execution using setTimeout
        // setTimeout(() => {

        //     // Reset cart state and clear local storage
        //     dispatch(userActions.clearCart({ userId: currentUser.id }));
        // }, 1500);

        const clearCartItems = async () => {
            setTimeout(() => {
                setCartLen(0);
                setCartItems([]);
            }, 1000);
            
            var myHeaders = new Headers();
            myHeaders.append("userId", currentUser.id);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
            };

            const response = await fetch("http://104.196.11.202:8080/v1/item/clear", requestOptions);
            const result = await response.text();
            console.log("Cart cleared: " + result);
            console.log("response: " + response.status + " " + response.statusText);
        };
        clearCartItems();


        const sendToOrderList = async () => {
            try {
                var orderHeaders = new Headers();
                orderHeaders.append("userId", currentUser.id);
                orderHeaders.append("Content-Type", "application/json");
                console.log("responseOrderId: " + responseOrderId);
                console.log("currentUser.id: " + currentUser.id);

                var raw = JSON.stringify({
                    "userId": null,
                    "orderId": responseOrderId,
                    "price": tempTotalPr,
                    "status": "Delivered",
                    "returnId": null,
                    "product": cartItems.map(item => item.itemId).join(','),
                    "productCategory": cartItems.map(item => item.category).join(','),
                    "policyId": policyData.cartPolicies[0].policyID,
                    "policyName": policyData.cartPolicies[0].policyName,
                    timestamp: new Date().getTime()
                });

                var requestOptions = {
                method: 'POST',
                headers: orderHeaders,
                body: raw,
                redirect: 'follow'
                };

                const result = await fetch("http://104.196.11.202:8080/v1/order", requestOptions);
                console.log("response aaya: " + result.status + " " + result.statusText);
            } catch (error) {
                console.log("error: " + error);
            }
        };

        sendToOrderList();
    };


    useEffect(() => {
        let items = [];
        let tempTotalPr = 0;
        for (let i = 0; i < cartItems.length; i++) {
            items.push({
                "product": {
                    "identifierType": "UPC",
                    "identifier": cartItems[i].itemId,
                    "category": cartItems[i].category ?? "Fashion"
                },
                "count": props.cartItems[i].quantity,
                "perItemPrice": {
                    "currencyCode": "840",
                    "amount": cartItems[i].price
                }
            });
            tempTotalPr += cartItems[i].price * cartItems[i].quantity;
        }
        const fetchData = async () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "*/*");
            myHeaders.append("Authorization", "13001zpuu-ea29f7bbf8f642d6a72c0a3149eed053");
            myHeaders.append("x-merchant-id", "13001");
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Access-Control-Allow-Origin","*");

            var raw = JSON.stringify({
            "merchantCartID": "ABB10903-" + new Date().getTime(),
            "previousCartId": "ABB10903-1234",
            "totalCartCost": {
                "currencyCode": "840",
                "amount": tempTotalPr
            },
            "cart": items,
            "customer": {
                "name": currentUser.name,
                "deviceFingerprints": localStorage.getItem("visitorId") ? [
                    {
                        algorithm: "beacon",
                        fingerPrint: localStorage.getItem("visitorId").split("#")[1] ,
                    }
                ] : [],
                "contactInformation": [
                    {
                        "contactType": "ContactAddress",
                        "address": {
                            "line1": "9241",
                            "line2": "Scott City Monument",
                            "line3": null,
                            "line4": null,
                            "city": "Kansas",
                            "state": "KS",
                            "postalCode": "67871",
                            "country": "US",
                            "geoCode": {
                                "longitude": "-100.908056",
                                "latitude": "38.478535"
                            }
                        },
                        "addressType": "Billing"
                    },
                    {
                        "contactType": "ContactAddress",
                        "address": {
                            "line1": "3706",
                            "line2": "Parkshire Dr XYZ",
                            "line3": null,
                            "line4": null,
                            "city": "Pearland",
                            "state": "TX",
                            "postalCode": "17214",
                            "country": "US",
                            "geoCode": {
                                "longitude": "-95.498518",
                                "latitude": "29.467289"
                            }
                        },
                        "addressType": "Mailing"
                    },
                {
                    "contactType": "EMail",
                    "address": currentUser.email
                },
                {
                    "contactType": "Phone",
                    "phone": 9922112233
                }
                ]
            },
            "merchant": {
                "brand": "Milo",
                "locationID": "OUTLET-123"
            }
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            try {
                let result = {};
                const response = await fetch(hostUrl + "/cart/analyze", requestOptions);
                if (!(response.ok || response.status == 400)) {
                    console.log(typeof response.status);
                    console.log(response.status);
                    throw new Error('Error fetching policy');
                }
                if (response.status == 400) {
                    result = await response.text();
                    let tempCartId = Date.now().toString();
                    result = {
                        "pinchCartID": tempCartId,
                        "merchantCartID": "ABB10903-45",
                        "productPolicies": null,
                        "cartPolicies": [
                            {
                                "policyID": "204",
                                "policyName": "Allow Return & Refund After Inspection Only",
                                "policyTerms": "REVIEW",
                                "policyType": null
                            }
                        ]
                    }
                } else {
                    result = await response.json();
                }
                
                setPolicyData(result);
                let cartPolicies = [];
                for (let i = 0; i < result.cartPolicies.length; i++) {
                    cartPolicies.push(result.cartPolicies[i].policyID);
                }
                console.log("result: " + result.pinchCartID);
                setCartId(result.pinchCartID);
                setPolicies(cartPolicies);
                // console.log("cartId: " + cartId);


            } catch (error) {
                console.log("error: " + error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (useDummyData) {
            const dummyData = {
                "pinchCartID": "0bddaceb-d669-46eb-853d-116cea2e330a",
                "merchantCartID": "ABB10903-45",
                "productPolicies": null,
                "cartPolicies": [
                    {
                        "policyID": "204",
                        "policyName": "Allow Return & Refund After Inspection Only",
                        "policyTerms": "REVIEW",
                        "policyType": null
                    }
                ]
            }
            setPolicyData(dummyData);
            setLoading(false);
        } else {
            if (cartItems.length > 0) {
                fetchData();
            }
        }

    }, [cartLen]);

    const classes = useStyles();

    if (loading) {
        return (
            <div>
                Loading....
            </div>
        );
    }
    if (error) {
        return (
            <div>
                {error.message}
            </div>
        );
    }

    return (
        <div>
            <div>
                <h3><b>Return Policy</b></h3>
                <p>
                    {policyData.cartPolicies ? policyData.cartPolicies[0].policyName : null}    
                </p>
            </div>
            <br />
            <br />
            <button onClick={handleClickOpen} className='spAddTOCart checkoutBtn'>
                { checkoutLoader ? 
                    <span className="button-icon">
                        {/* <i className="fa fa-spinner fa-spin loading-icon" aria-hidden="true"></i> */}
                        <i className="fa fa-check success-icon" aria-hidden="true"></i>
                    </span> :             
                    <div className=' text-center'>
                        Place order
                    </div> 
                }
            </button>

            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <h2 id="simple-modal-title">Order Placed!</h2>
                    <p id="simple-modal-description">Your order has been placed successfully.</p>
                </div>
            </Modal>
        </div>
    );
}

export default Policy;
